import React from 'react'
import './style.css'

export const Footer = () => {
    return (
        <div className='Footer'>
            <div className='Footer__title'>
                До встречи
            </div>
            <div className='Footer__subtitle'>
                A & A
            </div>
        </div>
    )
}
