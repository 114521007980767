const API_URL = 'https://sharipovs.ru/api/'

const addToFormData = (formData, object) => {
    for (let [key, value] of Object.entries(object)) {
        formData.append(key, value)
    }
    return formData
}

export const getData = async (name) => {
    return fetch(API_URL + 'invite/?name=' + name)
        .then(res => res.json())
        .then(res => res)
        .catch(e => { return { status: 'error', message: 'Ошибка интернет соединения' } })
}

export const sendAcceptInvite = async (name, accept) => {
    let formData = new FormData()
    formData.append('name', name)
    formData.append('accept', accept)
    return fetch(API_URL + 'invite/', {
        method: "POST",
        body: formData,
    })
        .then(res => res.json())
        .then(res => res)
        .catch(e => { return { status: 'error', message: 'Ошибка интернет соединения' } })
}

export const sendQuestionInvite = async (result) => {
    let data = Object.assign({}, result);
    let formData = new FormData()
    delete data.accept;
    delete data.welcome_title;
    delete data.welcome_text;
    delete data.has_accept;
    delete data.saved_question;
    addToFormData(formData, data)
    return fetch(API_URL + 'invite/', {
        method: "POST",
        body: formData,
    })
        .then(res => res.json())
        .then(res => res)
        .catch(e => { return { status: 'error', message: 'Ошибка интернет соединения' } })
}