import React, { useEffect, useState } from 'react'
import './style.css'

const ENDTIME = new Date(1722067200 * 1000)

const calculateTimer = (endtime) => {
    let currenttime = Date.parse(new Date())
    if (currenttime <= endtime) {
        let t = endtime - currenttime;
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        let days = Math.floor(t / (1000 * 60 * 60 * 24) % 7);
        let weeks = Math.floor(t / (1000 * 60 * 60 * 24 * 7));
        return { weeks, days, hours, minutes, seconds }
    } else {
        let t = currenttime - endtime;
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
        let days = Math.floor(t / (1000 * 60 * 60 * 24) % 7);
        let weeks = Math.floor(t / (1000 * 60 * 60 * 24 * 7));
        return { weeks, days, hours, minutes, seconds }
    }
}

const declOfNum = (n, titles) => {
    return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
}

export const Timer = () => {

    const [data, setData] = useState(calculateTimer(ENDTIME))

    useEffect(() => {
        const interval = setInterval(() => {
            setData(calculateTimer(ENDTIME))
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    return (
        <div className='Timer'>
            <div className='Timer__item'>
                <div className='Timer__item_value'>{('0' + data.weeks).slice(-2)}</div>
                <div className='Timer__item_label'>{declOfNum(data.weeks, ['Неделя', 'Недели', 'Недель'])}</div>
            </div>
            <div className='Timer__item'>
                <div className='Timer__item_value'>{('0' + data.days).slice(-2)}</div>
                <div className='Timer__item_label'>{declOfNum(data.days, ['День', 'Дня', 'Дней'])}</div>
            </div>
            <div className='Timer__item'>
                <div className='Timer__item_value'>{('0' + data.hours).slice(-2)}</div>
                <div className='Timer__item_label'>{declOfNum(data.hours, ['Час', 'Часа', 'Часов'])}</div>
            </div>
            <div className='Timer__item'>
                <div className='Timer__item_value'>{('0' + data.minutes).slice(-2)}</div>
                <div className='Timer__item_label'>{declOfNum(data.minutes, ['Минута', 'Минуты', 'Минут'])}</div>
            </div>
            <div className='Timer__item'>
                <div className='Timer__item_value'>{('0' + data.seconds).slice(-2)}</div>
                <div className='Timer__item_label'>{declOfNum(data.seconds, ['Секунда', 'Секунды', 'Секунд'])}</div>
            </div>
        </div>
    )
}
