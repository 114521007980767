import React from 'react'

export const BeforeRestaurant = ({ className }) => {
    return (
        <div className={className}>
            <svg width="48" height="48" viewBox="0 0 131 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M105.34 36.0345C105.27 35.8452 105.222 35.6079 105.198 35.3493C105.009 27.0346 105.505 18.1985 99.6704 11.3959C95.8438 6.93055 89.3238 4.16791 83.5131 3.95591C71.1358 3.50657 61.9227 12.2706 56.017 22.2386C56.0879 22.6866 56.1827 23.1132 56.2535 23.5612C56.5608 26.1132 52.592 26.0652 52.2612 23.5612C49.9227 5.91591 29.1358 0.623909 15.2222 8.89191C1.37952 17.1132 2.65512 33.3639 10.2847 45.3879C21.6707 63.2932 40.8994 75.1279 58.167 86.5372C61.5686 84.0346 65.0415 81.6719 68.4664 79.2146C57.9306 69.4586 49.7806 56.9626 54.4342 44.9866C60.9306 28.2146 83.8211 31.9239 93.0571 43.6879C96.3398 40.4746 100.734 37.7345 105.34 36.0345ZM126.009 54.9786C127.097 48.6719 126.245 46.9239 121.404 42.4119C115.521 36.9319 110.017 36.6706 102.624 40.5919C99.7184 42.1279 97.0718 44.2532 94.5451 46.3332C94.5691 48.0106 92.0411 49.0492 90.8358 47.3252C85.6398 39.7666 75.9546 35.4439 67.0494 39.0092C55.4978 43.5919 57.8598 56.7746 63.2931 65.7266C70.2379 77.1132 80.9624 85.3332 92.0184 92.4199C92.1598 92.4906 92.2531 92.6092 92.3478 92.7039L92.6318 92.4439C103.262 85.4986 113.418 78.2932 120.458 67.4506C122.962 63.5772 125.206 59.5386 126.009 54.9786ZM130.569 48.3879C133.569 67.8999 110.158 88.3572 94.3318 95.3252C93.3398 95.7746 92.4424 95.3252 92.0411 94.6172C91.6864 94.8532 91.2144 94.9479 90.6944 94.6879C85.2144 91.9946 77.868 87.3652 71.1123 81.5772C66.9546 84.5306 62.6784 87.3412 58.0249 89.4906C57.1749 89.8919 56.4664 89.3719 56.3478 88.6879C33.8129 78.4826 -10.6913 47.2066 2.34778 17.0186C12.1514 -5.61206 47.7259 -3.58009 55.0956 18.5306C60.742 6.67191 73.0014 -1.78543 86.6078 0.317234C104.23 3.05723 110.656 18.8372 108.742 34.9706C118.592 32.5372 128.537 35.2532 130.569 48.3879Z" fill="#171717" />
                <path d="M123.213 48.291C123.379 49.211 122.269 50.015 121.56 49.259C120.379 48.007 119.669 46.4483 118.348 45.291C116.907 44.0617 115.205 43.235 113.363 42.739C112.229 42.4323 112.725 40.8017 113.835 41.015C117.379 41.6283 122.409 44.4403 123.213 48.291Z" fill="#171717" />
                <path d="M116.766 46.1884C117.615 47.1324 116.292 48.479 115.371 47.5817C114.474 46.707 113.599 45.8817 112.49 45.267C111.474 44.6764 110.339 44.3697 109.3 43.8497C108.734 43.543 109.04 42.715 109.631 42.6924C112.135 42.527 115.135 44.3924 116.766 46.1884Z" fill="#171717" />
                <path d="M13.1259 21.3501C13.9297 20.3581 15.5119 21.6581 14.8505 22.6968C13.7406 24.5155 13.2213 26.2862 13.2447 28.4368C13.2447 29.4288 11.7797 29.6182 11.5202 28.6728C10.8354 26.1222 11.4723 23.4061 13.1259 21.3501Z" fill="#171717" />
                <path d="M10.8355 34.9103C11.1183 35.9729 9.60685 36.6343 9.06311 35.665C7.07925 32.0743 6.29951 26.9489 7.74071 23.0276C8.14178 21.9169 9.96098 22.0116 9.91418 23.3343C9.84271 25.201 9.55991 27.0436 9.65418 28.9329C9.74845 30.9889 10.3156 32.9249 10.8355 34.9103Z" fill="#171717" />
            </svg>
        </div>
    )
}
