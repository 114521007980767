import React from 'react'
import './style.css'

export const Details = () => {
    return (
        <div className='Details'>
            {/* <DetailsElement /> */}
            <div className='Title'>
                Детали
            </div>
            <div className='Details__subtitle'>Цветы</div>
            <div className='Details__text'>
                Пожалуйста, не дарите нам цветы, мы не успеем насладиться их красотой, ведь мы отправляемся в путешествие. Но если вы желаете сделать нам комплимент, то будем признательны, если вы добавите эту сумму в нашу копилку
            </div>
        </div>
    )
}
