import React from 'react'
import './style.css'
import { Button } from '../Button'

export const Accept = ({ sendAccept, loading, accept, refAccept }) => {
    return (
        <div className='Accept' ref={refAccept}>
            <Button
                loading={loading}
                onClick={accept !== '1' ? () => sendAccept('1') : () => { }}
                mode={accept === '1' && 'success'}>
                {accept === '1' ? 'Приглашение принято' : 'Принять приглашение'}
            </Button>
        </div>
    )
}
