import React from 'react'
import './style.css'
import { Heart } from '../icons/Heart'

export const Calendar = () => {
    return (

        <div className='Calendar'>
            <div className='Calendar__item'>
                <div className='Calendar__item_header'>Июль</div>
                <div className="Calendar__days">
                    <div className="Calendar__weeks">Пн</div>
                    <div className="Calendar__weeks">Вт</div>
                    <div className="Calendar__weeks">Ср</div>
                    <div className="Calendar__weeks">Чт</div>
                    <div className="Calendar__weeks">Пт</div>
                    <div className="Calendar__weeks Calendar__weeks_holiday">Сб</div>
                    <div className="Calendar__weeks Calendar__weeks_holiday">Вс</div>
                    <div className="Calendar__day">01</div>
                    <div className="Calendar__day">02</div>
                    <div className="Calendar__day">03</div>
                    <div className="Calendar__day">04</div>
                    <div className="Calendar__day">05</div>
                    <div className="Calendar__day Calendar__day_holiday">06</div>
                    <div className="Calendar__day Calendar__day_holiday">07</div>
                    <div className="Calendar__day">08</div>
                    <div className="Calendar__day">09</div>
                    <div className="Calendar__day">10</div>
                    <div className="Calendar__day">11</div>
                    <div className="Calendar__day">12</div>
                    <div className="Calendar__day Calendar__day_holiday">13</div>
                    <div className="Calendar__day Calendar__day_holiday">14</div>
                    <div className="Calendar__day">15</div>
                    <div className="Calendar__day">16</div>
                    <div className="Calendar__day">17</div>
                    <div className="Calendar__day">18</div>
                    <div className="Calendar__day">19</div>
                    <div className="Calendar__day Calendar__day_holiday">20</div>
                    <div className="Calendar__day Calendar__day_holiday">21</div>
                    <div className="Calendar__day">22</div>
                    <div className="Calendar__day">23</div>
                    <div className="Calendar__day">24</div>
                    <div className="Calendar__day">25</div>
                    <div className="Calendar__day">26</div>
                    <div className="Calendar__day Calendar__day_heart">
                        <Heart className={'Calendar__heart'} />
                        <div>27</div>
                    </div>
                    <div className="Calendar__day Calendar__day_holiday">28</div>
                    <div className="Calendar__day">29</div>
                    <div className="Calendar__day">30</div>
                    <div className="Calendar__day">31</div>
                    <div className="calender_day"></div>
                    <div className="calender_day"></div>
                    <div className="calender_day">
                    </div>
                    <div className="calender_day">
                    </div>
                </div>
            </div>
        </div>
    )
}
