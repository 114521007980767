import React from 'react'
import './style.css'
import { Button } from '../Button'



export const Question = ({ question, toggleQuestion, loading, sendQuestion, saved }) => {
    return (
        <div className='Question'>
            <div>
            <div className='Question__blocktitle'>У нас есть несколько вопросов:</div>
                <div className='Question__item'>
                    <div className='Question__title'>
                        Есть ли у вас особые предпочтения по еде?
                    </div>
                    <div>
                        <label className="Question__container">Не ем мясо
                            <input type="checkbox" name='food_meat' checked={question?.food_meat === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Не ем рыбу
                            <input type="checkbox" name='food_fish' checked={question?.food_fish === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Вегетарианец
                            <input type="checkbox" name='food_veg' checked={question?.food_veg === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                    </div>
                </div>
                <div className='Question__item'>
                    <div className='Question__title'>
                        Какой алкоголь вы предпочитаете?
                    </div>
                    <div>
                        <label className="Question__container">Вино
                            <input type="checkbox" name='alko_vine' checked={question?.alko_vine === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Шампанское
                            <input type="checkbox" name='alko_champagne' checked={question?.alko_champagne === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Виски
                            <input type="checkbox" name='alko_whisky' checked={question?.alko_whisky === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Коньяк
                            <input type="checkbox" name='alko_cognac' checked={question?.alko_cognac === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Коктейль Aperol Sprits
                            <input type="checkbox" name='alko_aperol' checked={question?.alko_aperol === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Коктейль Mai Tai
                            <input type="checkbox" name='alko_maitai' checked={question?.alko_maitai === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Коктейль Espresso Martini
                            <input type="checkbox" name='alko_martini' checked={question?.alko_martini === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Коктейль The Only Sunset

                            <input type="checkbox" name='alko_sunset' checked={question?.alko_sunset === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                        <label className="Question__container">Коктейль Whiskey Di Passion
                            <input type="checkbox" name='alko_whiskey' checked={question?.alko_whiskey === '1'} onChange={toggleQuestion} />
                            <span className="Question__checkmark_checkbox"></span>
                        </label>
                    </div>
                </div>
                <div className='Question__item'>
                    <div className='Question__title'>
                        Будет ли с вами ребёнок?
                    </div>
                    <div>
                        <label className="Question__container">Да
                            <input type="radio" name="children" checked={question?.children === '1'} onChange={toggleQuestion} value={'1'} />
                            <span className="Question__checkmark_radio"></span>
                        </label>
                        <label className="Question__container">Нет
                            <input type="radio" name="children" checked={question?.children !== '1'} onChange={toggleQuestion} value={'0'} />
                            <span className="Question__checkmark_radio"></span>
                        </label>
                    </div>
                </div>
                <div className='Question__item'>
                    <div className='Question__title'>
                        Есть особые пожелания?
                    </div>
                    <textarea placeholder='Например: хочу позвать +1, у меня аллергия и т.д.' name='message' value={question?.message} onChange={toggleQuestion} maxLength={2000}/>
                    {/* <div className='Question__description'>
                        Например: хочу позвать +1, у меня аллергия и т.д.
                    </div> */}
                </div>
                <Button loading={loading} onClick={!saved ? sendQuestion : () => { }} mode={saved && 'success'}>{saved ? 'Сохранено' : 'Сохранить'}</Button>
            </div>
        </div>
    )
}
