import React from 'react'
import './style.css'
import { BeforeWedding } from '../icons/BeforeWedding'
import { Wedding } from '../icons/Wedding'
import { BeforeRestaurant } from '../icons/BeforeRestaurant'
import { Restaurant } from '../icons/Restaurant'

export const Schedule = () => {
    return (
        <div className='Schedule'>
            <div className='Schedule__title'>
                Свадебное расписание
            </div>
            <div className='Schedule__container'>
                <div>
                    <div className='Schedule__item'>
                        <BeforeWedding className='Schedule__item_icon' />
                        <div className='Schedule__item_time'>
                            10:30<br />
                            27.07.2024
                        </div>
                        <div className='Schedule__item_text'>
                            Сбор гостей у Дворца бракосочетания № 1<br />
                            <a href='https://yandex.ru/maps/org/dvorets_brakosochetaniya_1/1311913400/' target='_blank' rel="noreferrer">Английская наб., 28</a>
                        </div>
                    </div>
                    <div className='Schedule__item'>
                        <Wedding className='Schedule__item_icon' />
                        <div className='Schedule__item_time'>
                            11:00<br />
                            27.07.2024
                        </div>
                        <div className='Schedule__item_text'>
                            Роспись <br />
                            <a href='https://yandex.ru/maps/org/dvorets_brakosochetaniya_1/1311913400/' target='_blank' rel="noreferrer">Санкт-Петербург, Английская наб., 28</a>
                        </div>
                    </div>
                    <div className='Schedule__item'>
                        <BeforeRestaurant className='Schedule__item_icon' />
                        <div className='Schedule__item_time'>
                            15:30<br />
                            27.07.2024
                        </div>
                        <div className='Schedule__item_text'>
                            Сбор гостей в банкетном зале VERONA LOFT <br />
                            <a href='https://yandex.ru/maps/org/verona_loft/136546686602/' target='_blank' rel="noreferrer">Державинский пер., 4А</a>
                        </div>
                    </div>
                    <div className='Schedule__item'>
                        <Restaurant className='Schedule__item_icon' />
                        <div className='Schedule__item_time'>
                            16:00<br />
                            27.07.2024
                        </div>
                        <div className='Schedule__item_text'>
                            Банкет <br />
                            <a href='https://yandex.ru/maps/org/verona_loft/136546686602/' target='_blank' rel="noreferrer">Державинский пер., 4А</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
