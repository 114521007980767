import { useCallback, useEffect, useRef, useState } from "react";
import {
  useParams,
  useHistory
} from "react-router-dom";
import './App.css';
import { Header } from './components/Header';
import { Chevron } from './components/Chevron';
import { Timer } from './components/Timer';
import { Welcome } from './components/Welcome';
import { Calendar } from './components/Calendar';
import { Schedule } from './components/Schedule/Schedule';
import { Dresscode } from './components/Dresscode';
import { Question } from './components/Question';
import { Accept } from './components/Accept';
import { getData, sendAcceptInvite, sendQuestionInvite } from "./api/api";
import AnimateHeight from "react-animate-height";
import { Details } from "./components/Details";
import { Footer } from "./components/Footer";

const App = () => {
  const history = useHistory()
  const { name } = useParams()



  const [data, setData] = useState({
    "welcome_title": "Привет!",
    "welcome_text": "Один день в этом году будет для нас особенным, и мы хотим провести его в кругу близких и друзей. С большим удовольствием приглашаем вас на знаменательный праздник - нашу свадьбу.",
    "has_accept": "0",
    "accept": "0"
  })
  const [loadingAccept, setLoadingAccept] = useState(false)
  const [loadingQuestion, setLoadingQuestion] = useState(false)

  const refAccept = useRef(null)

  const fetchData = useCallback(async (name) => {
    const response = await getData(name);
    if (response.status === 'ok') {
      setData(response.data)
    } else {
      history.replace('/')
    }
  }, [history])

  useEffect(() => {
    if (name) fetchData(name)
  }, [name, fetchData])

  const sendAccept = async (accept) => {
    try {
      setLoadingAccept(true)
      const response = await sendAcceptInvite(name, accept);
      if (response.status === 'ok') {
        setData({ ...data, accept: "1" })
        setTimeout(() => {
          refAccept?.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        }, 200);
      }
      else throw response?.message
      setLoadingAccept(false)
    } catch (error) {
      alert(error || 'Произошла ошибка, попробуйте ещё раз')
      console.log(error);
      setLoadingAccept(false)
    }
  }

  const toggleQuestion = (e) => {
    // setQuestion({ ...question, [e.target.name]: e.target.type !== 'checkbox' ? e.target.value : (e.target.checked ? '1' : '0') })
    setData({ ...data, saved_question: false, [e.target.name]: e.target.type !== 'checkbox' ? e.target.value : (e.target.checked ? '1' : '0') })
  }

  const sendQuestion = async () => {
    try {
      setLoadingQuestion(true)
      const response = await sendQuestionInvite(data)
      if (response.status === 'ok')
        setData({ ...data, saved_question: '1' })
      else throw response?.message
      setLoadingQuestion(false)
    } catch (error) {
      alert(error || 'Произошла ошибка, попробуйте ещё раз')
      console.log(error);
      setLoadingQuestion(false)
    }
  }

  console.log(data);

  return (<>
    <div className='Container'>
      <Header />
      <Timer />
      <Chevron />
    </div>
    <div className="Animation">
      <div className="Background__alternative">
        <div className='Container__second'>
          <Welcome title={data?.welcome_title} text={data?.welcome_text} />
        </div>
      </div>
      <div className="Background__image">
        <div className='Container__second'>
          <Calendar />
        </div>
      </div>
      <div className="Background__alternative">
        <div className='Container__second'>
          <Schedule />
        </div>
      </div>
      {data?.has_accept === '1' && <Accept sendAccept={sendAccept} loading={loadingAccept} accept={data?.accept} refAccept={refAccept} />}
      <AnimateHeight
        duration={500}
        height={data && data.accept !== '0' ? 'auto' : 0}
      >
        <div className='Container__second'>
          <Dresscode />
        </div>
        <div className="Background__svg">
          <div className='Container__second'>
            <Details />
          </div>
        </div>
        <div className='Container__second'>
          <Question question={data} toggleQuestion={toggleQuestion} sendQuestion={sendQuestion} loading={loadingQuestion} saved={data?.saved_question === '1'} />
        </div>
        <div className="Background__image-2">
          <Footer />
        </div>
      </AnimateHeight>
    </div>
  </>);
}

export default App;
