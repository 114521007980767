import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './index.css';
import App from './App';
import './fonts/beer_money.ttf';
import './fonts/Czizh.ttf';
import './fonts/Vetrino.ttf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Switch>
      <Route path="/:name" children={
        <App />}
      />
      <Route path="/" children={
        <App />}
      />
    </Switch>
  </Router>
);
